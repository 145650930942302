import React from "react";
import Navbar from "../../pages/Navbar";
import apple from "../../static/images/apple.svg";
import play from "../../static/images/play.svg";
import "./land.scss";
import mobile from "../../static/images/mobile.svg";

import cross from "../../static/images/cross.svg";
import check from "../../static/images/check.svg";
import house from "../../static/images/house.svg";
import {
  AppearingContainer,
  AppearSequentialContainer,
} from "react-appear-on-scroll";
import "react-appear-on-scroll/dist/index.css";
export default function landingPageSection() {
  return (
    <>
      <Navbar />
      <div className="landingsection ">
        <div className="container  innerlanding">
          <div className="leftsidelanding">
            <img src={mobile} />
          </div>
          <div className="rightsidelanding">
            <h1>
              Become A Landlord In Minutes & Develop Your Real Estate Portfolio{" "}
            </h1>
            <p>
              Commercial loans provide flexibility to invest in commercial
              properties or any other assets that are non-commercial. At
              PropReturns you can get instant loans from an array of 25+ banks
              at the lowest interest rates
            </p>

            <div className="labelplaystore">
              {" "}
              <div className="download">
                <img src={apple} />
              </div>
              <div className="download">
                <img src={play} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bannerMain">
        <div className="container sub ">
          <div className="bannercard">
            <h1>651</h1>
            <p>Investors</p>
          </div>
          <div className="bannercard">
            <h1>13</h1>
            <p>Properties</p>
          </div>
          <div className="bannercard">
            <h1>1.3CR</h1>
            <p>Invested</p>
          </div>
          <div
            className="bannercard"
            style={{
              width: "10%",
            }}
          >
            <h1>12.5CR</h1>
            <p>Market Cap</p>
          </div>
        </div>
      </div>

      <div className="container textscrollerSection">
        <h3>How It Works</h3>

        <AppearingContainer
          animationType="scale"
          transitionType="smooth"
          fading={true}
        >
          <div className="scroller-container">
            <div className="leftside">
              <div className="innsersideLeft">
                <div>
                  <div className="numbers">1</div>
                  <div className="Lineaccount"></div>
                </div>
                <h1>Create a free account & complete your kyc</h1>
              </div>
            </div>
            <div className="rightsidestep">
              <p>
                Commercial loans provide flexibility to invest in commercial
                properties or any other assets that are non-commercial. At
                PropReturns you can get instant loans from an.
              </p>
            </div>
          </div>
        </AppearingContainer>
        <AppearingContainer
          animationType="scale"
          transitionType="smooth"
          fading={true}
        >
          <div className="scroller-container">
            <div className="leftside">
              <div className="innsersideLeft">
                <div>
                  <div className="numbers">2</div>
                  <div className="Lineaccount"></div>
                </div>
                <h1>Discover your first investment property</h1>
              </div>
            </div>
            <div className="rightsidestep">
              <p>
                Commercial loans provide flexibility to invest in commercial
                properties or any other assets that are non-commercial. At
                PropReturns you can get instant loans from an.
              </p>
            </div>
          </div>
        </AppearingContainer>

        <AppearingContainer
          animationType="scale"
          transitionType="smooth"
          fading={true}
        >
          <div className="scroller-container">
            <div className="leftside">
              <div className="innsersideLeft">
                <div>
                  <div className="numbers">3</div>
                  <div className="Lineaccount"></div>
                </div>
                <h1>Fund your landlord account</h1>
              </div>
            </div>
            <div className="rightsidestep">
              <p>
                Commercial loans provide flexibility to invest in commercial
                properties or any other assets that are non-commercial. At
                PropReturns you can get instant loans from an.
              </p>
            </div>
          </div>
        </AppearingContainer>

        <AppearingContainer
          animationType="scale"
          transitionType="smooth"
          fading={true}
        >
          <div className="scroller-container">
            <div className="leftside">
              <div className="innsersideLeft">
                <div>
                  <div className="numbers">4</div>
                  <div className="Lineaccount"></div>
                </div>
                <h1>Buy the PropertyShares of your desired item</h1>
              </div>
            </div>
            <div className="rightsidestep">
              <p>
                Commercial loans provide flexibility to invest in commercial
                properties or any other assets that are non-commercial. At
                PropReturns you can get instant loans from an.
              </p>
            </div>
          </div>
        </AppearingContainer>

        <AppearingContainer
          animationType="scale"
          transitionType="smooth"
          fading={true}
        >
          <div className="scroller-container">
            <div className="leftside">
              <div className="innsersideLeft">
                <div>
                  <div className="numbers">5</div>
                  <div className="Lineaccount"></div>
                </div>
                <h1>Track your investment and collect dividends</h1>
              </div>
            </div>
            <div className="rightsidestep">
              <p>
                Commercial loans provide flexibility to invest in commercial
                properties or any other assets that are non-commercial. At
                PropReturns you can get instant loans from an.
              </p>
            </div>
          </div>
        </AppearingContainer>
        <AppearingContainer
          animationType="scale"
          transitionType="smooth"
          fading={true}
        >
          <div className="scroller-container">
            <div className="leftside">
              <div className="innsersideLeft">
                <div>
                  <div className="numbers">6</div>
                </div>
                <h1>Sell your property shares at any time</h1>
              </div>
            </div>
            <div className="rightsidestep">
              <p>
                Commercial loans provide flexibility to invest in commercial
                properties or any other assets that are non-commercial. At
                PropReturns you can get instant loans from an.
              </p>
            </div>
          </div>
        </AppearingContainer>
      </div>

      <div className="investemt">
        <div className="container">
          <h6>You Can Invest Into</h6>
          <div className="card-investement">
            {[1, 1, 2].map((item) => {
              return (
                <div className="invest-card">
                  <h1>Properties Directly</h1>
                  <p>
                    List your property on every online and offline marketplaces
                    with one app.
                  </p>
                  <div className="checkshow">
                    <img src={check} />
                    <h2>minimal investment required</h2>
                  </div>
                  <div className="checkshow">
                    <img src={check} />
                    <h2>minimal investment required</h2>
                  </div>
                  <div className="checkshow">
                    <img src={check} />
                    <h2>minimal investment required</h2>
                  </div>

                  <div className="showSectionImage">
                    <img src={house} />
                    <img src={cross} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { FULL_LOGO, LOGO_ICON } from "../../config";
import useWindowDimensions from "../../utils/WindowSize";
import classNames from "./navbar.module.scss";
import { useHistory } from "react-router-dom";
function Navbar() {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [navOpen, setNavOpen] = useState(false);
  const menuWrap = useSpring({
    opacity: navOpen ? 1 : 0,
    display: navOpen ? "flex" : "none",
    config: { duration: 500 },
  });
  const menuBgAnim = useSpring({
    scale: navOpen ? 200 : 0,
    config: { duration: 500 },
  });

  return (
    <div className={classNames.navbar}>
      <div className="container navbar">
        <img
          onClick={() => history.push("/")}
          src={width > 768 ? FULL_LOGO : LOGO_ICON}
          alt=""
        />
        <div className={classNames.buttonWwrap}>
          <span className={classNames.buttonText}>Menu</span>

          <animated.div
            className={classNames.menuBg}
            style={menuBgAnim}
          ></animated.div>
          <animated.div className={classNames.menu} style={menuWrap}>
            <div
           
            >
              Home
            </div>
            <div className={classNames.menuItem}>About us</div>
            <div className={classNames.menuItem}>Investments</div>
            <div className={classNames.menuItem}>Team</div>
            <div className={classNames.menuItem}>Projects</div>
            <div className={classNames.menuItem}>News</div>
            <div className={classNames.menuItem}>About us</div>
          </animated.div>

          <div
            onClick={() => setNavOpen(!navOpen)}
            className={
              "hamburger hamburger--squeeze" + (navOpen ? " is-active" : "")
            }
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;

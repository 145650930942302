import React from "react";
import "./Overview.scss";
import borderimage from "../../static/images/borderimage.jpg";
import vist from "../../static/images/vist.jpg";
export default function Overview() {
  return (
    <>
      <section id="LeaseTerms">
        <div className="OverView">
          <div className="OverviewText">Lease terms</div>
          <div className="lineBoxnowrap">
            <div className="boxshow">
              <div className="imagecover">
                <div className="one">1</div>
                <img className="borderimag" src={borderimage} />
              </div>

              <div className="avgREntal">Tenant Background</div>
              <div className="avgpercentage">Real Estate Consulting</div>
            </div>

            <div className="boxshow">
              <div className="imagecover">
                <div className="one">2</div>
                <img className="borderimag" src={borderimage} />
              </div>
              <div className="avgREntal">Lease tenure</div>
              <div className="avgpercentage">5 years</div>
            </div>

            <div className="boxshow">
              <div className="imagecover">
                <div className="one">3</div>
              </div>
              <div className="avgREntal">Lockin period</div>
              <div className="avgpercentage">5 Years</div>
            </div>
          </div>

          <div className="lineBox">
            <div className="boxlease">
              <div className="avgREntal">Tenant Background</div>
              <div className="avgpercentage">Real Estate Consulting</div>
            </div>
            <div className="boxlease">
              <div className="avgREntal">Lease tenure</div>
              <div className="avgpercentage">5 years</div>
            </div>
            <div className="boxlease">
              <div className="avgREntal">Lockin period</div>
              <div className="avgpercentage">5 Years</div>
            </div>
            <div className="boxlease">
              <div className="avgREntal">Rent escalation</div>
              <div className="avgpercentage">15% every 3 years</div>
            </div>
            <div className="boxlease">
              <div className="avgREntal">Security deposit</div>
              <div className="avgpercentage">15% every 3 years</div>
            </div>
            <div className="boxlease">
              <div className="avgREntal">Property tax paid by</div>
              <div className="avgpercentage">Owner</div>
            </div>
            <div className="boxlease">
              <div className="avgREntal"> Maintenance cost paid by</div>
              <div className="avgpercentage">Owner</div>
            </div>
          </div>
        </div>

        <img className="vistImage" src={vist}></img>
      </section>
    </>
  );
}

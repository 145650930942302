import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "./propetyDetail.scss";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import ScrollSpy from "react-scrollspy";
import Lease from "./Lase";
import Overview from "./Overview";
import Priceing from "./Priceing";
import Financials from "./Financials";
import Footer from "../LandingPage/Footer";
import { HideOn } from "react-hide-on-scroll";
import Navbar from "../../components/Navbar";
export default function index() {












  const [name, setname] = useState("relative");
  const changeNavbarColor = async () => {
    if (window.scrollY < 510) {
      setname("");
    } else {
      setname("fixed");
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <>
    <Navbar/>
      <div className="bgcolor">
        <div className="propertyDetail">
          <div className="container inner">
            <div className="left-side">
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass=""
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                partialVisible
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 1,
                    partialVisibilityGutter: 0,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 0,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1,
                    partialVisibilityGutter: 0,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {[1, 2, 6, 7, 7, 8, 4].map((item) => {
                  return (
                    <div className="imagesectiom">
                      <div className="overlapdata"></div>
                      <img src="https://www.propreturns.com/_next/image?url=https%3A%2F%2Fpropreturns-website.s3.ap-south-1.amazonaws.com%2Fstatic-assets%2Fimages%2Fproperties%2FPID_1752%2Fcbkc-4.webp&w=1920&q=75" />
                    </div>
                  );
                })}
              </Carousel>
              <div className="office-dash">
                <div className="label-box">
                  <div className="office">Office</div>
                  <div className="Preleased">Preleased</div>
                </div>
                <div className="label-box">
                  <div
                    className="Preleased"
                    style={{
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      class="text-base text-gray-500"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"></path>
                    </svg>
                  </div>
                  <div
                    className="Preleased"
                    style={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      borderLeft: "0.5px solid #d3d3d3",
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 1024 1024"
                      class="text-xl text-gray-500"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z"></path>
                    </svg>
                  </div>
                </div>
              </div>

              <div className="bottom-section">
                <div className="Preleased">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 384 512"
                    class="text-base"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                  </svg>
                  See On Map
                </div>
              </div>
            </div>

            <div className="rightside">
              <div className="lefsideindersub">
                <div className="line">
                  <div className="currency">₹5.10 Cr</div>
                  <div className="rio">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      class="mr-2 text-lg sm:text-xl"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
                    </svg>
                    ROI Model
                  </div>
                </div>

                <div className="location">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 384 512"
                    class="mr-1 text-base mt-0.5 text-neutral-400"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                  </svg>
                  Mumbai, Bandra Kurla Complex
                </div>

                <div
                  className="line"
                  style={{
                    marginTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  <div className="purchase">Purchase price</div>
                  <div className="purchaseMoney">₹5.10 Cr</div>
                </div>

                <RangeSlider
                  className="single-thumb"
                  id="range-slider-gradient"
                  defaultValue={[0, 50]}
                  thumbsDisabled={[true, false]}
                  rangeSlideDisabled={true}
                />
                <div>
                  <div className="reset">Reset</div>
                </div>

                <div className="labelcheck">
                  <div
                    className="labeltext"
                    style={{
                      background: "#fff",
                    }}
                  >
                    On Carpet
                  </div>
                  <div className="labeltext">On Built-up</div>
                </div>
                <div className="labelpriceAvgPrice">
                  <div
                    className="left-avg"
                    style={{
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    <div className="avgprice">
                      Avg. Rental Yield
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        class="text-neutral-500 mt-1"
                        height="15"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                        <path d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                      </svg>
                    </div>
                    <div className="avgBellowprice">7.11%</div>
                  </div>
                  <div className="left-avg">
                    <div className="avgprice">
                      Price per sq. ft.
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        class="text-neutral-500 mt-1"
                        height="15"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                        <path d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                      </svg>
                    </div>
                    <div className="avgBellowprice">₹77,273</div>
                  </div>
                </div>

                <div className="labelbottompricelist">
                  <div className="imgSvg">
                    <img src="https://www.propreturns.com/assets/img/details/monthly-rental.svg" />
                  </div>
                  <div
                    style={{
                      paddingLeft: "10px",
                    }}
                  >
                    <div className="avgprice">
                      Earn monthly rental income of
                    </div>
                    <div className="avgBellowprice">₹2,85,000</div>
                  </div>
                </div>

                <div
                  className="line"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <div className="whatsup">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      class="text-xl mr-1"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
                    </svg>
                    Whatsapp
                  </div>
                  <div className="interested">I'm interested</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HideOn divID="footerdata">
        <div
          className="tab-section"
          style={{
            position: name,
          }}
        >
          <div className="container">
            <ScrollSpy
              className="member-nav"
              items={[
                "Overview",
                "LeaseTerms",
                "Pricing",
                "Financials",
                "SimilarProperties",
              ]}
              currentClassName="isCurrent"
            >
              <li>
                <a
                  href="#Overview"
                  ref={React.createRef()}
                  className="member-text"
                >
                  Overview
                </a>
              </li>
              <li>
                <a
                onUpdate={(e)=>alert("xcv")}
                  href="#LeaseTerms"
                  ref={React.createRef()}
                  className="member-text"
                >
                  {" "}
                  Lease Terms
                </a>
              </li>
              <li>
                <a
                  href="#Pricing"
                  ref={React.createRef()}
                  className="member-text"
                >
                  Pricing
                </a>
              </li>

              <li>
                <a
                  href="#Financials"
                  ref={React.createRef()}
                  className="member-text"
                >
                  {" "}
                  Financials
                </a>
              </li>
              <li>
                <a
                  href="#SimilarProperties"
                  ref={React.createRef()}
                  className="member-text"
                >
                  {" "}
                  Similar Properties
                </a>
              </li>
            </ScrollSpy>
          </div>
        </div>

</HideOn>
        <div
          style={{
            display: "flex",
          }}
          className="container"
        >
          <div className="leftsideProps">
            <Overview />
            <Lease />
            <Priceing />
            <Financials />
          </div>
        </div>
        <div className=" container" id="SimilarProperties">
          <div className=" finialcardbottom">
            <div className="OverviewText">You may also like</div>

            <div className="flex-card">
              {[1, 2, 6].map((item) => {
                return (
                  <div className="rentcarsoleshow">
                    <div className="overlap"></div>
                    <img src="https://www.propreturns.com/_next/image?url=https%3A%2F%2Fd33fl5dh8y8vra.cloudfront.net%2Fstatic-assets%2Fimages%2Fproperties%2FPID_1770%2Ftheobroma.webp&w=1920&q=10" />
                    <div
                      className="office-dash"
                      style={{
                        justifyContent: "flex-end",
                        right: "0",
                        top: " 4px",
                      }}
                    >
                      <div className="label-box">
                        <div
                          className="Preleased"
                          style={{
                            borderTopRightRadius: "0px",
                            borderBottomRightRadius: "0px",
                          }}
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 16 16"
                            class="text-base text-gray-500"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"></path>
                          </svg>
                        </div>
                        <div
                          className="Preleased"
                          style={{
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                            borderLeft: "0.5px solid #d3d3d3",
                          }}
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 1024 1024"
                            class="text-xl text-gray-500"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    {/* <h3>Shop</h3> */}

                    <div className="locationtype">
                      <div>
                        <div className="currency">₹5.10 Cr</div>
                        <div className="location">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 384 512"
                            class="mr-1 text-base mt-0.5 text-neutral-400"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                          </svg>
                          Mumbai,Kurla Complex
                        </div>
                      </div>
                      <div>
                        <div className="avgREntal">Avg. Rental Yield</div>
                        <div className="avgpercentage">7.11%</div>
                      </div>
                    </div>
                    <div
                      className="typeyeild"
                      style={{
                        marginTop: "12px",
                      }}
                    >
                      <h4>Avg. Rental Yield</h4>
                      <h6>₹1,84,000</h6>
                    </div>
                    <div className="typeyeild">
                      <h4>Rental </h4>
                      <h6>₹100</h6>
                    </div>

                    <div className="typeyeild">
                      <h4>Avg</h4>
                      <h6>0</h6>
                    </div>
                    <div
                      className="typeyeild"
                      style={{
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <h4>Avg. Rental Yield</h4>
                      <h6>9%</h6>
                    </div>

                    <div className="Highlights">
                      <div className="textHighlights">Highlights:</div>
                      <div className="subhight">Prime Area</div>
                      <div className="subhight">Rental Escalation Soon</div>
                    </div>

                    <div className="labelbutton">
                      <div className="wImage">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 448 512"
                          class="text-white text-2xl"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                        </svg>
                      </div>
                      <div className="Iinterested">I'm interested</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

import React from "react";
import "./nav.scss";
import logo from "../../static/images/logo.svg";
export default function index() {
  return (
    <div className="nav-menu">
      <div className="container menu">
        <div className="leftside">
          <img src={logo} />
        </div>
        <div className="right-side">
          <h3
   
            style={{
              fontWeight: "700",
              color: "#4B5BB2",
            }}
          >
            About Us
          </h3>
          <h3>Markets</h3>
          <h3>For Sellers</h3>
          <h3>Markets</h3>
          <h3>For Builders</h3>
          <h3>Learn</h3>
          <label className="login">Login</label>
          <label className="register">Register</label>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Switch, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import ComingSoon from "./pages/ComingSoon";
import PropertyDetail from "./pages/PropertyDetail";
import Property from "./pages/PropertyPage"
import Landing from "./pages/LandingPage/landingPageSection"
function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/comingsoon" component={ComingSoon} />
      <Route exact path="/property" component={Property} />
      <Route exact path="/property/detail" component={PropertyDetail} />
    </Switch>
  );
}

export default Routes;

import React from "react";
import "./pro.scss";
import "../PropertyDetail/propetyDetail.scss";
import { useHistory } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../LandingPage/Footer";
export default function index() {
  const history = useHistory();
  return (
    <>
      <Navbar />
      <div className="bgcolor">
        <div className="container">
          <div className="preleasedText">
            Preleased properties for investment
          </div>

          <div className="topTabFiltering">
            <div className="sectionTab">
              <div className="texttab">Location</div>
              <div className="inputDash">
                <input
                  className="input"
                  style={{
                    width: "210px",
                  }}
                  placeholder="Search By Locality"
                />
              </div>
            </div>

            <div className="sectionTab">
              <div className="texttab">City</div>
              <div className="inputDash">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  class=" transition-all duration-200"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
                </svg>
                <input className="input" placeholder="No preference" />
              </div>
            </div>
            <div className="sectionTab">
              <div className="texttab">Price</div>
              <div className="inputDash">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  class=" transition-all duration-200"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
                </svg>
                <input className="input" placeholder="Select Your Budget" />
              </div>
            </div>
            <div className="sectionTab">
              <div className="texttab">Assets Type</div>
              <div className="inputDash">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  class=" transition-all duration-200"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
                </svg>
                <input className="input" placeholder="No preference" />
              </div>
            </div>

            <div className="clearAll">Clear All</div>
          </div>

          <div className="showText">
            Showing <span>755</span> properties
          </div>

          <div className="contnettab">
            <div
              className="tablistproperty"
              style={{
                backgroundColor: "#000",
                color: "#fff",
              }}
            >
              All
            </div>
            <div className="tablistproperty">Hot Picks</div>
            <div className="tablistproperty">High Yield</div>
            <div className="tablistproperty">New Listing</div>
          </div>
        </div>

        <div className=" container" id="SimilarProperties">
          <div
            className=" finialcardbottom"
            style={{
              padding: "0px",
              boxShadow: "none",
            }}
          >
            <div className="flex-card">
              {[
                1,
                2,
                6,
                1,
                1,
                1,
                11,
                1,
                1,
                1,
                1,
                1,
                11,
                1,
                1,
                1,
                1,
                1,
                1,
                11,
                1,
                11,
                1,
                1,
                1,
                1,
                1,
                11,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                11,
                1,
                ,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
              ].map((item) => {
                return (
                  <div
                    className="rentcarsoleshow"
                    style={{
                      marginBottom: "1rem",
                      boxShadow: "0px",
                    }}
                    onClick={() => history.push("/property/detail")}
                  >
                    <div className="overlap"></div>
                    <img src="https://www.propreturns.com/_next/image?url=https%3A%2F%2Fd33fl5dh8y8vra.cloudfront.net%2Fstatic-assets%2Fimages%2Fproperties%2FPID_1770%2Ftheobroma.webp&w=1920&q=10" />
                    <div
                      className="office-dash"
                      style={{
                        justifyContent: "flex-end",
                        right: "0",
                        top: " 4px",
                      }}
                    >
                      <div className="label-box">
                        <div
                          className="Preleased"
                          style={{
                            borderTopRightRadius: "0px",
                            borderBottomRightRadius: "0px",
                          }}
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 16 16"
                            class="text-base text-gray-500"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"></path>
                          </svg>
                        </div>
                        <div
                          className="Preleased"
                          style={{
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                            borderLeft: "0.5px solid #d3d3d3",
                          }}
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 1024 1024"
                            class="text-xl text-gray-500"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    {/* <h3>Shop</h3> */}

                    <div className="locationtype">
                      <div>
                        <div className="currency">₹5.10 Cr</div>
                        <div className="location">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 384 512"
                            class="mr-1 text-base mt-0.5 text-neutral-400"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                          </svg>
                          Mumbai,Kurla Complex
                        </div>
                      </div>
                      <div>
                        <div className="avgREntal">Avg. Rental Yield</div>
                        <div className="avgpercentage">7.11%</div>
                      </div>
                    </div>
                    <div
                      className="typeyeild"
                      style={{
                        marginTop: "12px",
                      }}
                    >
                      <h4>Avg. Rental Yield</h4>
                      <h6>₹1,84,000</h6>
                    </div>
                    <div className="typeyeild">
                      <h4>Rental </h4>
                      <h6>₹100</h6>
                    </div>

                    <div className="typeyeild">
                      <h4>Avg</h4>
                      <h6>0</h6>
                    </div>
                    <div
                      className="typeyeild"
                      style={{
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <h4>Avg. Rental Yield</h4>
                      <h6>9%</h6>
                    </div>

                    <div className="Highlights">
                      <div className="textHighlights">Highlights:</div>
                      <div className="subhight">Prime Area</div>
                      <div className="subhight">Rental Escalation Soon</div>
                    </div>

                    <div className="labelbutton">
                      <div className="wImage">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 448 512"
                          class="text-white text-2xl"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                        </svg>
                      </div>
                      <div className="Iinterested">I'm interested</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

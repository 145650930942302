import React from "react";
import "./footer.scss";
export default function Footer() {
  return (
    <>
 

      <footer id="footerdata"  class="footer-area pt-8" >
        <div class="container px-4 mx-auto ">
          <div class="flex md:flex-row pb-8">
            <div class="w-full md:w-1/2 lg:w-1/4 md:px-2">
              <div class="flex flex-col md:block cursor-pointer mt-0 mb-6">
                <div class="rounded-xl flex-none bg-cover bg-center h-40 w-full relative">
                  <span className="span1">
                    <img
                      alt="A 3-step guide to choosing the right real estate developer in 2022! "
                      sizes="100vw"
                      src="https://www.propreturns.com/_next/image?url=https%3A%2F%2Fd33fl5dh8y8vra.cloudfront.net%2Fstrapi-bucket%2Fchristina_wocintechchat_com_U_Tw3j_ao_IKM_unsplash_c206b6bb99.webp&w=1920&q=75"
                      className="rounded-xl img1"
                    />
                  </span>
                </div>
                <div class="flex flex-col justify-center md:mt-3 ml-2 md:ml-0">
                  <h3 class="text-sm md:text-base text-white font-bold my-1 hover:underline">
                    A 3-step guide to choosing the right real estate developer
                    in 2022!{" "}
                  </h3>
                  <div class="flex items-center my-1">
                    <span class="text-xxs text-neutral-50">
                      August 09, 2022
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 md:px-2">
              <div class="flex flex-col md:block cursor-pointer mt-0 mb-6">
                <div class="rounded-xl flex-none bg-cover bg-center h-40 w-full relative">
                  <span className="span1">
                    <img
                      alt="Top places near Mumbai for real estate investments"
                      sizes="100vw"
                      src="/https://www.propreturns.com/_next/image?url=https%3A%2F%2Fd33fl5dh8y8vra.cloudfront.net%2Fstrapi-bucket%2Fchristina_wocintechchat_com_U_Tw3j_ao_IKM_unsplash_c206b6bb99.webp&w=1920&q=75"
                      decoding="async"
                      data-nimg="fill"
                      class="rounded-xl img1"
                    />
                  </span>
                </div>
                <div class="flex flex-col justify-center md:mt-3 ml-2 md:ml-0">
                  <h3 class="text-sm md:text-base text-white font-bold my-1 hover:underline">
                    Top places near Mumbai for real estate investments
                  </h3>
                  <div class="flex items-center my-1">
                    <span class="text-xxs text-neutral-50">May 30, 2022</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 md:px-2">
              <div class="flex flex-col md:block cursor-pointer mt-0 mb-6">
                <div class="rounded-xl flex-none bg-cover bg-center h-40 w-full relative">
                  <span className="span1">
                    <img
                      alt="Top places near Mumbai for real estate investments"
                      sizes="100vw"
                      src="https://www.propreturns.com/_next/image?url=https%3A%2F%2Fd33fl5dh8y8vra.cloudfront.net%2Fstrapi-bucket%2Fchristina_wocintechchat_com_U_Tw3j_ao_IKM_unsplash_c206b6bb99.webp&w=1920&q=75"
                      decoding="async"
                      data-nimg="fill"
                      class="rounded-xl img1"
                    />
                  </span>
                </div>
                <div class="flex flex-col justify-center md:mt-3 ml-2 md:ml-0">
                  <h3 class="text-sm md:text-base text-white font-bold my-1 hover:underline">
                    Top places near Mumbai for real estate investments
                  </h3>
                  <div class="flex items-center my-1">
                    <span class="text-xxs text-neutral-50">May 30, 2022</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 md:px-2">
              <div class="flex-none md:h-40 w-full flex justify-center items-center">
                <span class="text-xl font-bold text-white cursor-pointer">
                  Read more +
                </span>
              </div>
            </div>
          </div>
          <div class="place-items-center md:flex justify-between">
            <div class="">
              <a class="footer-logo">
                <span className="span2">
                  <img
                    className="img3"
                    alt="logo"
                    src="/_next/image?url=https%3A%2F%2Fd33fl5dh8y8vra.cloudfront.net%2Fassets%2Fimg%2Flogo_new.webp&amp;w=384&amp;q=75"
                    decoding="async"
                    data-nimg="fixed"
                  />
                </span>
              </a>
            </div>
            <div class="md:flex mt-2">
              <div class="cursor-pointer mx-2">
                <h6 class="footer-text font-bold">Properties</h6>
              </div>
              <a>
                <div class="cursor-pointer mx-2">
                  <h6 class="footer-text font-bold">About Us</h6>
                </div>
              </a>
              <a>
                <div class="cursor-pointer mx-2">
                  <h6 class="footer-text font-bold">Blogs</h6>
                </div>
              </a>
              <a>
                <div class="cursor-pointer mx-2">
                  <h6 class="footer-text font-bold">Privacy Policy</h6>
                </div>
              </a>
              <a>
                <div class="cursor-pointer mx-2">
                  <h6 class="footer-text font-bold">Terms and Conditions</h6>
                </div>
              </a>
              <a>
                <div class="cursor-pointer mx-2">
                  <h6 class="footer-text font-bold">Sitemap</h6>
                </div>
              </a>
            </div>
          </div>
          <div class="h-[1px] rounded-full bg-white bg-opacity-20 my-6"></div>
          <div class="footer-internal-linkings">
            <div class="grid xxs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:gap-2">
              <ul class="pl-0">
                <a class="text-white text-base hover:underline">
                  Preleased Office space
                </a>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Mumbai</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Delhi</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Noida</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Gurgaon</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Navi Mumbai</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Thane</a>
                </li>
              </ul>
              <ul class="">
                <a class="text-white text-base hover:underline">
                  Preleased Shops
                </a>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Mumbai</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Delhi</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Noida</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Gurgaon</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Navi Mumbai</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Thane</a>
                </li>
              </ul>
              <ul class="">
                <a class="text-white text-base hover:underline">
                  Preleased Bank
                </a>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Investment in Mumbai</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Investment in Delhi</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Investment in Noida</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Investment in Gurgaon</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">
                    for Investment in Navi Mumbai
                  </a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Investment in Thane</a>
                </li>
              </ul>
              <ul class="">
                <a class="text-white text-base hover:underline">Warehouses</a>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Investment in Mumbai</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Investment in Delhi</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Investment in Noida</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Investment in Gurgaon</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">
                    for Investment in Navi Mumbai
                  </a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Investment in Thane</a>
                </li>
              </ul>
              <ul class="">
                <a class="text-white text-base hover:underline">Plots</a>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">
                    Investment Plots in Mumbai Region
                  </a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">
                    Investment Plots in Delhi NCR
                  </a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">
                    Residential Plots in Mumbai Region
                  </a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">
                    Residential Plots in Delhi NCR
                  </a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">
                    Commercial Plots in Mumbai Region
                  </a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">
                    Commercial Plots in Delhi NCR
                  </a>
                </li>
              </ul>
              <ul class="">
                <a class="text-white text-base hover:underline">
                  Vacant Commercial Properties
                </a>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Mumbai Region</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">for Sale in Delhi NCR</a>
                </li>
              </ul>
              <ul class="">
                <a class="text-white text-base hover:underline">REITs</a>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">Invest in Mindspace REIT</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">Invest in Embassy REIT</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">Invest in Brookfield REIT</a>
                </li>
              </ul>
              <ul class="">
                <a class="text-white text-base hover:underline">Tools</a>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">
                    Real estate returns calculator
                  </a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">EMI calculator</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">Property valuation finder</a>
                </li>
                <li class="mt-1 mb-1">
                  <a class="footer-internal-links">RERA project finder</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="w-full mt-10">
            <div class="flex justify-end">
              <div class="flex items-center w-max justify-between -mb-3">
                <a>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    class="w-6 h-6 text-white mr-2"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
                  </svg>
                </a>
                <a>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    class="w-6 h-6 text-white mr-2"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                  </svg>
                </a>
                <a>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    class="w-6 h-6 text-white mr-2"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                  </svg>
                </a>
                <a>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    class="w-6 h-6 text-white mr-2"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                  </svg>
                </a>
                <a>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 576 512"
                    class="w-6 h-6 text-white"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div class="flex justify-start -mb-3 -mt-2">
              <div class="Tooltip-root relative cursor-pointer flex items-center z-50 undefined">
                <div class="Tooltip-Wrapper w-full" role="presentation">
                  <p class="text-white text-base font-semibold cursor-pointer">
                    RERA Registered
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      class="inline-block mb-1 text-lg"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M12 19h2V6l6.394 2.74a1 1 0 0 1 .606.92V19h2v2H1v-2h2V5.65a1 1 0 0 1 .594-.914l7.703-3.424A.5.5 0 0 1 12 1.77V19z"></path>
                      </g>
                    </svg>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="h-[1px] rounded-full bg-white bg-opacity-20 my-6"></div>
          <div>
            <div class="grid grid-cols-1 pb-24">
              <p class="text-white text-sm mb-2">
                Copyright © 2022 | Propreturns Real Estate Technology Pvt. Ltd.
              </p>
              <div class="col-span-1 text-[#ffffff90] text-xs">
                <p>
                  PropReturns does not own the properties and is not responsible
                  for any property information on the platform
                  (www.propreturns.com). All information on the platform
                  (www.propreturns.com) is subject to negotiation, availability
                  and changes. We are not responsible for any problems (legal,
                  physical,etc.) arising with the property and every property
                  needs to be subject to due diligence and inspection by the
                  investor.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

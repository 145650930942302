import React from "react";
import "./Financials.scss";
import RangeSlider from "react-range-slider-input";
export default function Financials() {
  return (
    <div className="fincial" id="Financials">
      <div
        className="rightside"
        style={{
          width: "100%",
          marginLeft: "0",
        }}
      >

        <div className="lefsideindersub">
        <div className="OverviewText">Financials</div>
          <div className="labelpriceAvgPrice">
            <div
              className="left-avg"
              style={{
                borderRight: "1px solid #ddd",
              }}
            >
              <div className="avgprice">
                IRR
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 1024 1024"
                  class="text-neutral-500 mt-1"
                  height="15"
                  width="15"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                  <path d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                </svg>
              </div>
              <div className="avgBellowprice">7.11%</div>
            </div>
            <div
              className="left-avg"
              style={{
                borderRight: "1px solid #ddd",
              }}
            >
              <div className="avgprice">
                Investment
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 1024 1024"
                  class="text-neutral-500 mt-1"
                  height="15"
                  width="15"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                  <path d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                </svg>
              </div>
              <div className="avgBellowprice">1.71</div>
            </div>
            <div
              className="left-avg"
              style={{
                borderRight: "1px solid #ddd",
              }}
            >
              <div className="avgprice">
                Avg. Rental
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 1024 1024"
                  class="text-neutral-500 mt-1"
                  height="15"
                  width="15"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                  <path d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                </svg>
              </div>
              <div className="avgBellowprice">7.11%</div>
            </div>
            <div className="left-avg">
              <div className="avgprice">
                Total Equity
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 1024 1024"
                  class="text-neutral-500 mt-1"
                  height="15"
                  width="15"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                  <path d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                </svg>
              </div>
              <div className="avgBellowprice">₹5,28,30,000</div>
            </div>
          </div>
          <div className="fini-full">
            <div className="sub">
              <div
                className="line"
                style={{
                  marginTop: "30px",
                  paddingBottom: "15px",
                }}
              >
                <div className="purchase">Purchase price</div>
                <div
                  className="purchaseMoney"
                  style={{
                    color: "#000",
                  }}
                >
                  ₹5.10 Cr
                </div>
              </div>

              <RangeSlider
                className="single-thumb"
                id="range-slider-gradient"
                defaultValue={[0, 50]}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
              />

              <div
                className="line"
                style={{
                  marginTop: "30px",
                  paddingBottom: "15px",
                }}
              >
                <div className="purchase">Down Payment</div>
                <div
                  className="purchaseMoney"
                  style={{
                    color: "#000",
                  }}
                >
                  ₹5.10 Cr
                </div>
              </div>

              <RangeSlider
                className="single-thumb"
                id="range-slider-gradient"
                defaultValue={[0, 50]}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
              />

              <div
                className="line"
                style={{
                  marginTop: "30px",
                  paddingBottom: "15px",
                }}
              >
                <div className="purchase">Interest Rate (p.a.)</div>
                <div
                  className="purchaseMoney"
                  style={{
                    color: "#000",
                  }}
                >
                  ₹5.10 Cr
                </div>
              </div>

              <RangeSlider
                className="single-thumb"
                id="range-slider-gradient"
                defaultValue={[0, 50]}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
              />
            </div>
            <div
              className="sub"
              style={{
                height: "15rem",
              }}
            >
              <div
                className="line"
                style={{
                  marginTop: "30px",
                  paddingBottom: "15px",
                }}
              >
                <div className="purchase">Interest Rate (p.a.)</div>
                <div
                  className="purchaseMoney"
                  style={{
                    color: "#000",
                  }}
                >
                  ₹5.10 Cr
                </div>
              </div>

              <RangeSlider
                className="single-thumb"
                id="range-slider-gradient"
                defaultValue={[0, 50]}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
              />
              <div
                className="line"
                style={{
                  marginTop: "30px",
                  paddingBottom: "15px",
                }}
              >
                <div className="purchase">Interest Rate (p.a.)</div>
                <div
                  className="purchaseMoney"
                  style={{
                    color: "#000",
                  }}
                >
                  ₹5.10 Cr
                </div>
              </div>

              <RangeSlider
                className="single-thumb"
                id="range-slider-gradient"
                defaultValue={[0, 50]}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "./Overview.scss";
export default function Overview() {
  return (
    <section id="Overview">
      <div className="OverView">
        <div className="OverviewText">Overview</div>
        <div className="lineBox">
          <div className="boxavg">
            <div className="avgREntal">Avg. Rental Yield</div>
            <div className="avgpercentage">7.11%</div>
          </div>
          <div className="boxavg">
            <div className="avgREntal">Carpet Area</div>
            <div className="avgpercentage">660 sq. ft.</div>
          </div>
          <div className="boxavg">
            <div className="avgREntal">Lease tenure</div>
            <div className="avgpercentage">5 Years</div>
          </div>
          <div className="boxavg">
            <div className="avgREntal">Built-up Area</div>
            <div className="avgpercentage">-</div>
          </div>
        </div>

        <div className="Textdata">
          This commercial property is situated in Bandra Kurla Complex, Mumbai.
          The property is leased out to a well renowned Real Estate Consulting
          and generates a monthly rental income of Rs. 2,85,000. The total lease
          tenure is of 5 years, starting from 2022 with a rent escalation of 15%
          every 3 years. This property is priced at Rs. 5,10,00,000 and will
          fetch you an average rental yield of 7.11%!
        </div>

        <div className="lineBox">
          <div className="labelmodel">See financial model</div>
          <div className="callmodel">Schedule a call</div>
        </div>
      </div>
    </section>
  );
}

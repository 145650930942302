import React from "react";
import "./Precing.scss";
import { PieChart } from "react-minimal-pie-chart";
import RangeSlider from "react-range-slider-input";
export default function Priceing() {
  return (

    <div id="Pricing" className="PriceDash">
      <div className="rightside" style={{
        width:"50%"
      }}>
        <div className="">
          <div className="line">
            <div className="OverviewText">Pricing</div>
          </div>

          <div
            className="line"
            style={{
              marginTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <div className="purchase">Purchase price</div>
            <div className="purchaseMoney">₹5.10 Cr</div>
          </div>

          <RangeSlider
            className="single-thumb"
            id="range-slider-gradient"
            defaultValue={[0, 50]}
            thumbsDisabled={[true, false]}
            rangeSlideDisabled={true}
          />
          <div>
            <div className="reset">Reset</div>
          </div>

          <div className="priceingtabs">
            <div
              style={{
                display: "flex",
                alignItem: "center",
              }}
            >
              <div className="round1"></div>
              <div className="avgREntal">Purchase Price:</div>
            </div>
            <div
              className="avgpercentage"
              style={{
                marginTop: "0px",
              }}
            >
              ₹5,10,00,000
            </div>
          </div>
          <div className="priceingtabs">
            <div
              style={{
                display: "flex",
                alignItem: "center",
              }}
            >
              <div className="round2"></div>
              <div className="avgREntal">Reg. + Stamp Duty:</div>
            </div>
            <div
              className="avgpercentage"
              style={{
                marginTop: "0px",
              }}
            >
              ₹30,90,000
            </div>
          </div>
          <div className="priceingtabs">
            <div
              style={{
                display: "flex",
                alignItem: "flex-start",
              }}
            >
              <div className="round3"></div>
              <div className="avgREntal">Brokerage Charges </div>
            </div>
            <div
              className="avgpercentage"
              style={{
                marginTop: "0px",
              }}
            >
              ₹10,20,000
            </div>
          </div>
          <div className="priceingtabs">
            <div
              style={{
                display: "flex",
                alignItem: "flex-start",
              }}
            >
              <div className="round4"></div>
              <div className="avgREntal">Purchase Price:</div>
            </div>
            <div
              className="avgpercentage"
              style={{
                marginTop: "0px",
              }}
            >
              ₹5,10,00,000
            </div>
          </div>
          <div className="priceingtabs">
            <div
              style={{
                display: "flex",
                alignItem: "flex-start",
              }}
            >
              <div className="round5"></div>
              <div className="avgREntal">Additional Charges:</div>
            </div>
            <div
              className="avgpercentage"
              style={{
                marginTop: "0px",
              }}
            >
              0
            </div>
          </div>
        </div>
      </div>
      <div className="piechart">
        <PieChart
          lineWidth={30}
          rounded
          data={[
            { title: "One", value: 10, color: "#E38627" },
            { title: "Two", value: 15, color: "#C13C37" },
            { title: "Three", value: 20, color: "#6A2135" },
            { title: "Three", value: 20, color: "#624562" },
            { title: "Three", value: 20, color: "#908876" },
          ]}
        />
        ;
      </div>
    </div>
  );
}
